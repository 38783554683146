import React, { Suspense, useState, useEffect, useLayoutEffect } from 'react';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';

import AppNav from '~/components/navigation/app-nav';
import ErrorBoundary from '~/components/error/error-boundary';
import Fallback from '~/components/fallback';
import Footer from '~/components/footer';
import AuthRoute from '~/components/auth-route';
import PreviewBanner from '~/components/preview-banner';
import TopBanner from '~/components/static/promo-banner/top-banner';
import { useModal } from '~/features/modals/modal-actions';
import * as r from '~/routes';
import { safeLazy } from '~/utils/safe-lazy';
import {
  analyticsBeacon,
  DeprecatedEventType,
  EventType,
} from '~/utils/analytics';
import {
  hideGladlyChat,
  showGladlyChat,
} from '~/utils/analytics/plugins/gladly';
import { saveCouponFromQueryParams } from '~/features/cart/cart';
import { useFlashMessage } from '~/features/flash-messages/flash-message-actions';
import { useSession } from '~/features/session/session-actions';
import {
  BROWN_SPOT_ID_FLAG,
  PEST_PLAN_FLAG,
  REGIONAL_SEO_FLAG,
  USER_CREATION_TOOL_FLAG,
} from '~/feature-flags';
import { useGoogleOneTap } from '~/hooks/use-google-one-tap';
import { useDocumentHead } from '~/hooks/use-document-head';
import { useVwoExperiment } from '~/hooks/use-vwo-experiment';

const HomePage = safeLazy(() => import('~/components/static/home/home-page'));
const GetStartedPage = safeLazy(() =>
  import('~/components/static/alt-home/get-started/get-started-page')
);
const NeighborsPage = safeLazy(() =>
  import('~/components/static/alt-home/neighbors/neighbors-page')
);
const IngredientsPage = safeLazy(() =>
  import('~/components/static/ingredients/ingredients-page')
);
const SmartLawnPlanPage = safeLazy(() =>
  import('~/components/static/smart-lawn-plan/smart-lawn-plan-page')
);
const SignInPage = safeLazy(() =>
  import('~/components/sessions/sign-in/sign-in-page')
);
const GuestCheckoutPage = safeLazy(() =>
  import('~/components/sessions/guest-checkout/guest-checkout-page')
);
const ForgotPasswordPage = safeLazy(() =>
  import('~/components/sessions/forgot-password/forgot-password-page')
);
const MagicLinkSignInPage = safeLazy(() =>
  import('~/components/sessions/magic-link-sign-in-page')
);
const NotFoundPage = safeLazy(() =>
  import('~/components/error/not-found-page')
);
const NewLawnPage = safeLazy(() =>
  import('~/components/lawns/new/new-lawn-page')
);
const LawnDetailsPage = safeLazy(() =>
  import('~/components/lawns/details/lawn-details-page')
);
const LawnQuestionnairePage = safeLazy(() =>
  import('~/components/lawns/questionnaire/lawn-questionnaire-page')
);
const FunnelProductsPage = safeLazy(() =>
  import('~/components/plans/funnel-products-page')
);
const CartPage = safeLazy(() => import('~/components/cart/cart-page'));
const CheckoutShippingPage = safeLazy(() =>
  import('~/components/checkout/checkout-shipping-page')
);
const CheckoutPaymentPage = safeLazy(() =>
  import('~/components/checkout/checkout-payment-page')
);
const CheckoutConfirmationPage = safeLazy(() =>
  import('~/components/checkout/checkout-confirmation-page')
);
const ModalSelector = safeLazy(() =>
  import('~/components/modals/modal-selector')
);
const FlashMessages = safeLazy(() =>
  import('~/components/messages/flash-messages')
);
const TermsAndConditionsPage = safeLazy(() =>
  import('~/components/terms-and-conditions/terms-and-conditions-page')
);
const Playground = safeLazy(() => import('~/components/playground'));
const GuaranteePage = safeLazy(() =>
  import('~/components/static/guarantee/guarantee-page')
);
const OurMissionPage = safeLazy(() =>
  import('~/components/static/our-mission/our-mission-page')
);
const ReviewsPage = safeLazy(() =>
  import('~/components/static/reviews/reviews-page')
);

const UserCreationTool = safeLazy(() =>
  import('~/components/static/home/user-creation-tool/user-creation-tool')
);

const AboutUsPage = safeLazy(() =>
  import('~/components/static/about-us/about-us-page')
);
const CareersPage = safeLazy(() =>
  import('~/components/static/careers/careers-page')
);
const RetailLandingPage = safeLazy(() =>
  import('~/components/static/retail/retail-landing-page')
);
const PrivacySettingsPage = safeLazy(() =>
  import('~/components/static/privacy-settings-page')
);
const HelpCenterPage = safeLazy(() =>
  import('~/components/static/help-center/help-center-page')
);
const ShedHome = safeLazy(() => import('~/components/the-shed/shed-home'));
const ShedAllArticles = safeLazy(() =>
  import('~/components/the-shed/tags-page/tags-page')
);
const ShedCategoryPage = safeLazy(() =>
  import('~/components/the-shed/category-page/category-page')
);
const ShedInstructionsPage = safeLazy(() =>
  import('~/components/the-shed/category-page/product-instructions-page')
);
const ShedArticlePage = safeLazy(() =>
  import('~/components/the-shed/article/article-page')
);
const MyPlanPage = safeLazy(() =>
  import('~/components/account/my-plan/my-plan-page')
);
const LawnDataPage = safeLazy(() =>
  import('~/components/account/lawn-data/lawn-data-page')
);
const AccountPage = safeLazy(() => import('~/components/account/account-page'));

const ReferralLandingPage = safeLazy(() =>
  import('~/components/static/referral-landing-page/referral-landing-page')
);

const SmartsPage = safeLazy(() => import('~/components/plans/smarts-page'));

const PlanBundlePage = safeLazy(() => {
  return import('~/components/plans/bundles-page/plan-bundle-page');
});

const CancellationBenefitsPage = safeLazy(() =>
  import('~/components/cancellation/cancellation-benefits-page')
);
const CancellationReasonsPage = safeLazy(() =>
  import('~/components/cancellation/cancellation-reasons-page')
);
const CancellationOffersPage = safeLazy(() =>
  import('~/components/cancellation/cancellation-offers-page')
);
const CancellationConfirmationPage = safeLazy(() =>
  import('~/components/cancellation/cancellation-confirmation-page')
);

const SoilTestRegistration = safeLazy(() =>
  import('~/components/soil-test/registration-page')
);

const PlantingGuidesPage = safeLazy(() =>
  import('~/components/the-shed/planting-guides-page')
);

const RadialAdminPage = safeLazy(() =>
  import('~/components/radial/radial-admin-page')
);

const AddToBoxPage = safeLazy(() =>
  import('~/components/account/add-to-box/add-to-box-page')
);

const SubscriptionsPage = safeLazy(() =>
  import('~/components/account/subscriptions/subscriptions-page')
);

const EditSubscriptionPage = safeLazy(() =>
  import('~/components/account/subscriptions/edit-subscription-page')
);

const ShopAllPage = safeLazy(() =>
  import('~/components/sunday-store/shop-all-page')
);

const SundayStoreProductDetailsPage = safeLazy(() =>
  import(
    '~/components/sunday-store/components/product-details/product-details-page'
  )
);

const SearchResultsPage = safeLazy(() =>
  import('~/components/search/search-results-page')
);

const SmartPestPlanPage = safeLazy(() =>
  import('~/components/static/smart-pest-plan/smart-pest-plan-page')
);

const NewPestPage = safeLazy(() =>
  import('~/components/pest/new/new-pest-page')
);

const PestQuestionnairePage = safeLazy(() =>
  import('~/components/pest/questionnaire/pest-questionnaire-page')
);

const PestSignUpPage = safeLazy(() =>
  import('~/components/pest/questionnaire/pest-signup-page')
);

const PestPlanBundlePage = safeLazy(() =>
  import('~/components/pest/bundles/pest-bundle-page')
);

const NextBestPage = safeLazy(() =>
  import('~/components/plans/add-ons/next-best-page')
);

const DynamicFormPreviewPage = safeLazy(() =>
  import('~/components/form/dynamic-form-preview-page')
);

const SeedFinderPage = safeLazy(() =>
  import('~/components/seed/seed-finder-page')
);

const SeedFinderResultsPage = safeLazy(() =>
  import('~/components/seed/seed-finder-results-page')
);

const PromoBannerPreviewPage = safeLazy(() =>
  import('~/components/static/promo-banner/promo-banner-preview-page')
);

const DevTools = safeLazy(() => import('~/components/dev-tools/dev-tools'));

const BrownSpotLandingPage = safeLazy(() =>
  import('~/components/tools/brown-spot/brown-spot-landing-page')
);
const BrownSpotResultsPage = safeLazy(() =>
  import('~/components/tools/brown-spot/brown-spot-results-page')
);

const StripeProvider = safeLazy(() =>
  import('~/components/form/stripe-provider')
);

const ZipcodeLawnSizePage = safeLazy(() =>
  import('~/components/lawns/zipcode-lawn-size/zipcode-lawn-size-page')
);

const NeighborsFunnelPage = safeLazy(() =>
  import('~/components/lawns/neighbors-funnel-page')
);

const RegionalSeoPage = safeLazy(() =>
  import('~/components/regional-seo-page/regional-seo-page')
);

const SSRPage = safeLazy(() => import('~/components/ssr-page'));

const App = () => {
  useGoogleOneTap();
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const { showModal } = useModal();
  const { showFlashMessage } = useFlashMessage();
  const { init: initSession, isInitializing, user } = useSession();
  const { init: initVwo } = useVwoExperiment();

  const corpStructuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Corporation',
    name: 'Sunday',
    url: 'https://www.getsunday.com',
    email: 'support@getsunday.com',
    contactPoint: {
      '@type': 'ContactPoint',
      contactType: 'customer service',
      email: 'support@getsunday.com',
      telephone: '(415) 903-6932',
    },
    knowsAbout: {
      '@type': 'Text',
      name: 'Lawn care',
    },
    legalName: {
      '@type': 'Text',
      name: 'Sunday, Inc.',
    },
    location: {
      '@type': 'Place',
      address: 'Boulder, Colorado',
    },
  };

  useDocumentHead('script', {
    type: 'application/ld+json',
    textContent: JSON.stringify(corpStructuredData),
  });

  useEffect(() => {
    initSession();
    initVwo();
  }, [initSession, initVwo]);

  useEffect(() => {
    saveCouponFromQueryParams(queryParams, (coupon) =>
      showFlashMessage(
        `Coupon code ${coupon.toUpperCase()} will automatically be added to your cart`,
        'info'
      )
    );
  }, [queryParams, showFlashMessage]);

  // Scroll to top of page on route change
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    analyticsBeacon.emit(
      DeprecatedEventType.PAGE_VIEW,
      { page: location.pathname },
      user
    );
  }, [location.pathname, user]);

  // Trigger Page Viewed event on route change
  useEffect(() => {
    analyticsBeacon.emit(EventType.PAGE_VIEWED);
  }, [location.pathname]);

  // Trigger 'UPDATE_PASSWORD' modal if user comes from a magic link, unless in 'authFail' flow
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (
      location?.state?.redirectFrom === 'magic-link' &&
      location?.state?.suppressModal !== true &&
      !queryParams.has('authFail') &&
      !queryParams.has('updatePaymentMethod') &&
      !location?.pathname?.includes('/lawn-plan')
    ) {
      showModal('UPDATE_PASSWORD', { creationMethod: 'magic-link' });
    }
    if (
      location?.state?.redirectFrom === 'magic-link' &&
      queryParams.has('updatePaymentMethod')
    ) {
      showModal('UPDATE_PAYMENT');
    }
  }, [showModal, location]);

  if (isInitializing) {
    return <Fallback />;
  }

  return (
    <>
      <PreviewBanner />

      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route
            element={<RetailLandingPage />}
            path={r.retailLandingPageRoute()}
          />

          <Route element={<GetStartedPage />} path={r.getStartedRoute()} />
          <Route element={<NeighborsPage />} path={r.neighborsPageRoute()} />

          {/* Checkout */}
          <Route
            element={
              <AuthRoute>
                <CheckoutShippingPage />
              </AuthRoute>
            }
            path={r.checkoutShippingRoute()}
          />
          <Route
            element={
              <AuthRoute>
                <CheckoutPaymentPage />
              </AuthRoute>
            }
            path={r.checkoutPaymentRoute()}
          />

          <Route
            element={<GuestCheckoutPage />}
            path={r.guestCheckoutRoute()}
          />

          <Route element={<AppLayoutRoutes />} path="*" />
        </Routes>
      </Suspense>

      <Suspense fallback={null}>
        <FlashMessages />
        <ModalSelector />
        <DevTools />
      </Suspense>
    </>
  );
};

export default App;

function AppLayoutRoutes() {
  const [navIsOpen, setNavIsOpen] = useState(false);

  useEffect(() => {
    if (navIsOpen) {
      hideGladlyChat();
    } else {
      showGladlyChat();
    }
  }, [navIsOpen]);

  const toggleNavOpen = () => {
    setNavIsOpen((o) => !o);
  };

  return (
    <>
      {USER_CREATION_TOOL_FLAG && (
        <StripeProvider>
          <UserCreationTool />
        </StripeProvider>
      )}

      <TopBanner />
      <AppNav isOpen={navIsOpen} toggleOpen={toggleNavOpen} />

      <Suspense fallback={<Fallback />}>
        <ErrorBoundary>
          <main className="main">
            <Routes>
              <Route element={<SSRPage />} path="/ssr" />
              {/* Static */}
              <Route element={<HomePage />} path={r.homeRoute()} />
              <Route
                element={<IngredientsPage />}
                path={r.ingredientsRoute()}
              />
              <Route
                element={<SmartLawnPlanPage />}
                path={r.customLawnPlanRoute()}
              />
              {PEST_PLAN_FLAG && (
                <Route
                  element={<SmartPestPlanPage />}
                  path={r.customPestPlanRoute()}
                />
              )}
              <Route
                element={<TermsAndConditionsPage />}
                path={r.termsRoute()}
              />
              <Route element={<GuaranteePage />} path={r.guaranteeRoute()} />
              <Route element={<OurMissionPage />} path={r.ourMissionRoute()} />
              <Route element={<ReviewsPage />} path={r.reviewsRoute()} />
              <Route element={<AboutUsPage />} path={r.aboutUsRoute()} />
              <Route element={<CareersPage />} path={r.careerPageRoute()} />
              <Route
                element={<PrivacySettingsPage />}
                path={r.privacySettingsRoute()}
              />
              <Route element={<HelpCenterPage />} path={r.helpCenterRoute()} />

              {/* Seed Finder */}
              <Route element={<SeedFinderPage />} path={r.seedFinderRoute()} />
              <Route
                element={
                  <AuthRoute>
                    <SeedFinderResultsPage />
                  </AuthRoute>
                }
                path={r.seedFinderResultsRoute()}
              />

              {/* Session management */}

              <Route element={<SignInPage />} path={r.signInRoute()} />
              <Route
                element={<ForgotPasswordPage />}
                path={r.forgotPasswordRoute()}
              />
              <Route
                element={<MagicLinkSignInPage />}
                path={r.magicLinkRoute()}
              />

              {/* Lawn */}
              <Route
                element={<ZipcodeLawnSizePage />}
                path={r.zipcodeLawnSizeRoute()}
              />
              <Route element={<NewLawnPage />} path={r.newLawnRoute()} />
              <Route
                element={
                  <AuthRoute>
                    <NeighborsFunnelPage />
                  </AuthRoute>
                }
                path={r.neighborsFunnelPageRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <LawnDetailsPage />
                  </AuthRoute>
                }
                path={r.lawnDetailsRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <LawnQuestionnairePage />
                  </AuthRoute>
                }
                path={r.lawnQuestionnaireRoute()}
              />

              {/* Pest */}
              {PEST_PLAN_FLAG && (
                <>
                  <Route element={<NewPestPage />} path={r.newPestRoute()} />
                  <Route
                    element={<PestQuestionnairePage />}
                    path={r.pestQuestionnaireRoute()}
                  />
                  <Route
                    element={<PestSignUpPage />}
                    path={r.pestSignUpRoute()}
                  />
                  <Route
                    element={
                      <AuthRoute>
                        <PestPlanBundlePage />
                      </AuthRoute>
                    }
                    path={r.pestPlanDetailsRoute()}
                  />
                  <Route
                    element={
                      <AuthRoute>
                        <NextBestPage />
                      </AuthRoute>
                    }
                    path={r.pestPlanNextBestRoute()}
                  />
                </>
              )}

              {/* Sunday Store */}
              <Route element={<ShopAllPage />} path={r.shopAllRoute()} />
              <Route
                element={<ShopAllPage />}
                path={r.categoryPageRoute(':category')}
              />
              <Route
                element={<ShopAllPage />}
                path={r.categoryPageRoute(':category', ':subCategory')}
              />
              {/* Sunday Store Products */}
              <Route
                element={<SundayStoreProductDetailsPage />}
                path={r.sundayStoreProductDetailsRoute()}
              />

              {/* Refer a Friend */}
              <Route
                element={
                  <AuthRoute>
                    <ReferralLandingPage />
                  </AuthRoute>
                }
                path={r.referralLandingPageRoute()}
              />

              {/* Lawn plan */}
              <Route
                element={
                  <AuthRoute>
                    <SmartsPage />
                  </AuthRoute>
                }
                path={r.planPreviewRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <PlanBundlePage />
                  </AuthRoute>
                }
                path={r.planDetailsRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <NextBestPage lawnFunnel />
                  </AuthRoute>
                }
                path={r.planNextBestRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <FunnelProductsPage />
                  </AuthRoute>
                }
                path={r.planAddOnsRoute()}
              />

              {/* Cart */}
              <Route element={<CartPage />} path={r.cartRoute()} />

              {/* Checkout */}
              <Route
                element={<CheckoutConfirmationPage />}
                path={r.checkoutConfirmationRoute()}
              />

              {/* Account */}
              <Route
                element={
                  <AuthRoute>
                    <AccountPage />
                  </AuthRoute>
                }
                path={r.accountSettingsRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <MyPlanPage />
                  </AuthRoute>
                }
                path={r.myPlanRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <LawnDataPage />
                  </AuthRoute>
                }
                path={r.lawnDataRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <AddToBoxPage />
                  </AuthRoute>
                }
                path={r.nextBoxRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <SubscriptionsPage />
                  </AuthRoute>
                }
                path={r.subscriptionsRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <EditSubscriptionPage />
                  </AuthRoute>
                }
                path={r.editSubscriptionRoute()}
              />

              {/* Cancellation */}
              <Route
                element={
                  <AuthRoute>
                    <CancellationBenefitsPage />
                  </AuthRoute>
                }
                path={r.cancellationBenefitsRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <CancellationReasonsPage />
                  </AuthRoute>
                }
                path={r.cancellationReasonsRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <CancellationOffersPage />
                  </AuthRoute>
                }
                path={r.cancellationOffersRoute()}
              />
              <Route
                element={
                  <AuthRoute>
                    <CancellationConfirmationPage />
                  </AuthRoute>
                }
                path={r.cancellationConfirmationRoute()}
              />

              {/* Amazon soil test registration */}
              <Route
                element={<SoilTestRegistration />}
                path={r.soilTestRegistration()}
              />

              {/* Shed */}
              <Route element={<ShedHome />} path={r.shedRoute()} />
              <Route element={<ShedAllArticles />} path={r.shedAllRoute()} />
              {/* Route for Planting Guides page */}
              <Route
                element={<PlantingGuidesPage />}
                path={r.plantingGuidesRoute()}
              />
              {/* Route for Product Instructions page */}
              <Route
                element={<ShedInstructionsPage />}
                path={r.shedCategoryRoute({ category: 'product-instructions' })}
              />
              {/* Route for primary category page */}
              <Route
                element={<ShedCategoryPage />}
                path={r.shedCategoryRoute()}
              />
              {/* Route for subcategory page */}
              <Route
                element={<ShedCategoryPage />}
                path={r.shedCategoryRoute({
                  category: ':noop',
                  subCategory: ':category',
                })}
              />
              {/* Route for articles without a subcategory */}
              <Route
                element={<ShedArticlePage />}
                path={r.shedArticleRoute()}
              />

              {/* Search */}
              <Route element={<SearchResultsPage />} path={r.searchRoute()} />

              {BROWN_SPOT_ID_FLAG && (
                <>
                  <Route
                    element={<BrownSpotLandingPage />}
                    path={r.brownSpotIdRoute()}
                  />
                  <Route
                    element={
                      <AuthRoute>
                        <BrownSpotResultsPage />
                      </AuthRoute>
                    }
                    path={r.brownSpotResultsRoute()}
                  />
                </>
              )}

              {/* Regional SEO Page */}
              {REGIONAL_SEO_FLAG && (
                <Route
                  element={<RegionalSeoPage />}
                  path={r.regionalSeoPageRoute()}
                />
              )}

              <Route
                element={<DynamicFormPreviewPage />}
                path={r.dynamicFormPreviewRoute()}
              />

              <Route
                element={<PromoBannerPreviewPage />}
                path={r.promoBannerPreviewRoute()}
              />

              <Route
                element={<RadialAdminPage />}
                path={r.radialAdminRoute()}
              />
              <Route element={<Playground />} path="/playground" />
              <Route element={<NotFoundPage />} path="*" />
            </Routes>
          </main>
        </ErrorBoundary>
        <Footer />
      </Suspense>
    </>
  );
}
