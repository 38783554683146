import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { api } from '~/api';
import { useSession } from '~/features/session/session-actions';
import { analyticsBeacon, EventType } from '~/utils/analytics';
import { captureException } from '~/utils/exception-tracking';

/**
 * Get the current user's lawn
 */
export const useLawn = (options = {}) => {
  const { user } = useSession();

  const { data, ...rest } = useQuery(['lawn'], () => api.lawns.all(), {
    enabled: Boolean(user),
    ...options,
  });

  return {
    lawn: data ? data[0] : null,
    ...rest,
  };
};

/**
 * Find a lawn by UUID. Typically useful for unauthenticated users
 */
export const useLawnByUuid = (lawnUuid, options = {}) => {
  const {
    data: lawn,
    isLoading,
    error,
  } = useQuery(['lawns', lawnUuid], () => api.lawns.find(lawnUuid), {
    enabled: Boolean(lawnUuid && lawnUuid !== ':lawnUuid'),
    ...options,
  });

  return {
    lawn,
    isLoading,
    error,
  };
};

export const useLawnMutations = () => {
  const queryClient = useQueryClient();
  const { refreshUser } = useSession();

  const createLawn = useCallback(async (address) => {
    const lawn = await api.lawns.create(address);
    analyticsBeacon.emit(EventType.ADDRESS_ENTERED, { lawn });
    return lawn;
  }, []);

  const updateLawn = useCallback(
    async (lawnUuid, lawn) => {
      const updatedLawn = await api.lawns.update(lawnUuid, lawn);
      queryClient.setQueryData(['lawn'], [updatedLawn]);
      queryClient.setQueryData(['lawns', updatedLawn.uuid], updatedLawn);
      queryClient.invalidateQueries(['cart']);
      queryClient.invalidateQueries(['sundayStoreSubsoil']);
      return updatedLawn;
    },
    [queryClient]
  );

  const claimLawn = useCallback(
    async (lawnUuid) => {
      const claimedLawn = await updateLawn(lawnUuid); // updating a lawn assigns it to the current user

      // Fire-and-forget cache updates
      await Promise.all([
        refreshUser(),
        queryClient.refetchQueries(['cart']),
        queryClient.refetchQueries(['card']),
      ]).catch((err) => {
        captureException(new Error('Error during claimLawn() data refresh'), {
          error: err,
          stringifiedError: JSON.stringify(err),
        });
      });

      return claimedLawn;
    },
    [queryClient, refreshUser, updateLawn]
  );

  return {
    claimLawn,
    createLawn,
    updateLawn,
  };
};

export const useWeatherData = (lawnUuid) => {
  const {
    data: weatherData,
    isLoading,
    error,
  } = useQuery(
    ['lawns.weather', lawnUuid],
    () => {
      return api.lawns
        .fetchWeatherData(lawnUuid)
        .then((response) => response?.dailyWeatherForecast);
    },
    {
      enabled: Boolean(lawnUuid),
    }
  );

  return {
    weatherData,
    isLoading,
    error,
  };
};

export const useLawnActions = (lawnUuid) => {
  const {
    data: actions,
    isLoading,
    error,
  } = useQuery(
    ['lawns.actions', lawnUuid],
    () => {
      return api.lawns
        .fetchLawnActions(lawnUuid)
        .then((response) => response?.dashboardActions);
    },
    {
      enabled: Boolean(lawnUuid),
    }
  );

  return {
    actions,
    isLoading,
    error,
  };
};

export const useLawnBundles = (
  lawnUuid,
  { enabled = true, paymentType, vwoTest } = {}
) => {
  const params = {
    lawnUuid,
    ...(!!paymentType && { payment_type: paymentType }),
    ...(!!vwoTest && { vwo_test: vwoTest }),
  };

  const {
    data: bundlesResponse,
    isLoading,
    error,
    ...rest
  } = useQuery(
    ['bundles', lawnUuid, paymentType, vwoTest],
    () => api.lawns.fetchBundles(params),
    {
      enabled: Boolean(lawnUuid && lawnUuid !== ':lawnUuid' && enabled),
      staleTime: 0,
      cacheTime: 0,
    }
  );

  return {
    bundles: bundlesResponse,
    isLoading,
    error,
    ...rest,
  };
};
